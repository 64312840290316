var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-text md-card-header-green"},[_c('div',{staticClass:"card-text"},[_c('h4',{staticClass:"title"},[_vm._v("Type Validation")])])]),_vm._v(" "),_c('md-card-content',[_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v("\n          Required Text\n        ")]),_vm._v(" "),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
            {'md-error': _vm.errors.has('required')},
            {'md-valid': !_vm.errors.has('required') && _vm.touched.required}]},[_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.required),expression:"modelValidations.required"}],attrs:{"data-vv-name":"required","type":"text","required":""},model:{value:(_vm.required),callback:function ($$v) {_vm.required=$$v},expression:"required"}}),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('required')),expression:"errors.has('required')"}],staticClass:"error"},[_vm._v("close")])],1),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('required') && _vm.touched.required),expression:"!errors.has('required') && touched.required"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_vm._v(" "),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("required")])])]),_vm._v(" "),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v("\n          Email\n        ")]),_vm._v(" "),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
            {'md-error': _vm.errors.has('email')},
            {'md-valid': !_vm.errors.has('email') && _vm.touched.email}]},[_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.email),expression:"modelValidations.email"}],attrs:{"data-vv-name":"email","type":"text","required":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('email')),expression:"errors.has('email')"}],staticClass:"error"},[_vm._v("close")])],1),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('email') && _vm.touched.email),expression:"!errors.has('email') && touched.email"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_vm._v(" "),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("email=\"true\"")])])]),_vm._v(" "),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v("\n          Number\n        ")]),_vm._v(" "),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
            {'md-error': _vm.errors.has('number')},
            {'md-valid': !_vm.errors.has('number') && _vm.touched.number}]},[_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.number),expression:"modelValidations.number"}],attrs:{"data-vv-name":"number","type":"number","required":""},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}}),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('number')),expression:"errors.has('number')"}],staticClass:"error"},[_vm._v("close")])],1),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('number') && _vm.touched.number),expression:"!errors.has('number') && touched.number"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_vm._v(" "),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("number=\"true\"")])])]),_vm._v(" "),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v("\n          Url\n        ")]),_vm._v(" "),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
            {'md-error': _vm.errors.has('url')},
            {'md-valid': !_vm.errors.has('url') && _vm.touched.url}]},[_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.url),expression:"modelValidations.url"}],attrs:{"data-vv-name":"url","type":"url","required":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('url')),expression:"errors.has('url')"}],staticClass:"errror"},[_vm._v("close")])],1),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('url') && _vm.touched.url),expression:"!errors.has('url') && touched.url"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_vm._v(" "),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("url=\"true\"")])])]),_vm._v(" "),_c('div',{staticClass:"md-layout"},[_c('label',{staticClass:"md-layout-item md-size-20 md-form-label"},[_vm._v("\n          Equal to\n        ")]),_vm._v(" "),_c('div',{staticClass:"md-layout-item"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
                {'md-error': _vm.errors.has('equalToSource')},
                {'md-valid': !_vm.errors.has('equalToSource') && _vm.touched.equalToSource}]},[_c('label',[_vm._v("#idSource")]),_vm._v(" "),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.equalToSource),expression:"modelValidations.equalToSource"}],ref:"equalToSource",attrs:{"data-vv-name":"equalToSource","type":"text","required":""},model:{value:(_vm.equalToSource),callback:function ($$v) {_vm.equalToSource=$$v},expression:"equalToSource"}}),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('equalToSource')),expression:"errors.has('equalToSource')"}],staticClass:"error"},[_vm._v("close")])],1),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('equalToSource') && _vm.touched.equalToSource),expression:"!errors.has('equalToSource') && touched.equalToSource"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
                {'md-error': _vm.errors.has('equalToDest')},
                {'md-valid': !_vm.errors.has('equalToDest') && _vm.touched.equalToDest}]},[_c('label',[_vm._v("#idDestination")]),_vm._v(" "),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.equalToDest),expression:"modelValidations.equalToDest"}],attrs:{"data-vv-name":"equalToDest","data-vv-as":"equalToSource","type":"text","required":""},model:{value:(_vm.equalToDest),callback:function ($$v) {_vm.equalToDest=$$v},expression:"equalToDest"}}),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('equalToDest')),expression:"errors.has('equalToDest')"}],staticClass:"error"},[_vm._v("close")])],1),_vm._v(" "),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('equalToDest') && _vm.touched.equalToDest),expression:"!errors.has('equalToDest') && touched.equalToDest"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1)])]),_vm._v(" "),_c('label',{staticClass:"md-layout-item md-size-20 md-label-on-right"},[_c('code',[_vm._v("equalTo=\"#idSource\"")])])])]),_vm._v(" "),_c('md-card-actions',{staticClass:"text-center"},[_c('md-button',{staticClass:"md-success",attrs:{"native-type":"submit"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate($event)}}},[_vm._v("Validate Inputs")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }